import Vue from 'vue';
import Vuex from 'vuex';
// 持久化缓存
import createPersistedState from 'vuex-persistedstate';

import config from "./modules/config";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    config
  },
  // 把vuex里面的所有数据都储存到了localStorage里面 不用担心刷新数据丢失的问题
  plugins: [createPersistedState()],
})