<template>
  <div @click.stop v-show="showKeyboard">
    <SimpleKeyboard ref="refSimpleKeyboard" :keyboardClass="'Keyboard'" @onChange="onChangeKeyboard" @empty="empty" />
  </div>
</template>
<script>
import SimpleKeyboard from "../SimpleKeyboard/index.vue";
export default {
  name: "Keyboard",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      inputFocus: false,
      showKeyboard: false, // 键盘默认隐藏
      value: "",
      key: "",
    };
  },
  watch: {
    key(val) {
      this.key = val;
      if (this.showKeyboard) {
        this.showKeyboard = false;
        setTimeout(() => {
          this.showKeyboard = true;
        }, 100);
      }
    },
  },
  methods: {
    // inpuit获取焦点显示虚拟键盘
    onInputFocus(res) {
      this.showKeyboard = true;
    },
    // 给输入框赋值
    onChangeKeyboard(input) {
      this.$emit("input", { value: input, key: this.key });
    },
    // 隐藏键盘 父组件调用
    closeInputFocus() {
      this.showKeyboard = false;
    },
    // 隐藏键盘 子组件调用
    closekeyboard() {
      this.showKeyboard = false;
    },
    // 清空输入框
    empty() {
      this.$emit("input", { value: "", key: this.key });
    },
    // 给虚拟键盘赋当前输入框的值
    setKeyboardInput(input) {
      this.$refs.refSimpleKeyboard.onChangeFocus(input);
    },
  },
  mounted() {
    const keyboardEle = document.querySelector(".Keyboard");
    document.addEventListener("click", (event) => {
      if (event.target.className !== 'el-input__inner' && event.target !== keyboardEle && !keyboardEle.contains(event.target)) {
        this.showKeyboard = false;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
// 键盘样式

.Keyboard {
  width: 985px;
  position: fixed;
  top: 768px;
  left: 50%;
  z-index: 2004;
  transform: translate(-50%, -100%);
}
</style>
