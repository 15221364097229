import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './libs/elementui'
import 'element-ui/lib/theme-chalk/index.css';
import ModuleTitle from "@/components/ModuleTitle";
import CustomEcharts from "@/components/CustomEcharts";
import KeysInput from "@/components/Keyboard"


Vue.component('ModuleTitle', ModuleTitle)
Vue.component('CustomEcharts', CustomEcharts)
Vue.component('KeysInput', KeysInput)
Vue.prototype.$webVideoCtrl = window.WebVideoCtrl
Vue.prototype.$ = window.$;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
