<template>
  <div class="layout">
    <VerifyPopup />
    <HeadCompoent />
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>


<script>
import HeadCompoent from '@/components/HeadCompoent'
import VerifyPopup from '@/components/VerifyPopup'
import { nanoid } from "nanoid";

export default {
  name: 'Layout',
  components: {
    HeadCompoent,
    VerifyPopup
  },
  methods: {
    setUuid() {
      const uuid = sessionStorage.getItem("uuid")
      
      if(!uuid) { //如果uuid不存在就生成一个
        sessionStorage.setItem("uuid", nanoid())
      }
    }
  },
  mounted() {
    this.setUuid();
  }
}
</script>


<style lang="scss" scoped>
@import "./index.scss"
</style>