<template>
  <div ref="echartsContainer" style="width: 100%; height: 100%;"></div>
</template>

<script>
import * as echarts from 'echarts';
 
export default {
  props: {
    options: {
      type: Object,
      default: ()=>({})
    }
  },
  name: 'EChartsComponent',
  data() {
    return {
      echartsContainer: null,
      chart: null
    }
  },
  methods: {
    initChart () {
      // 初始化echart
      this.chart = echarts.init(this.$refs.echartsContainer)
      this.chart.setOption(this.options, true)
    }
  },
  mounted() {
    this.initChart();
  },
  beforeDestroy () {
    this.chart.dispose()
    this.chart = null
  },
  watch: {
    options: {
      handler(v) {
        if(v) {
          this.chart.setOption(v);
        }

      },
      deep: true,
    }
  }
}
</script>