<template>
  <div class="head_com">
    <div class="left">
      <div :class="['item', active == '/monitor' ? 'active' : '']" @click="handleClick('/monitor')">视频监控</div>
      <div :class="['item', active == '/device' ? 'active' : '']" @click="handleClick('/device')">设备监测</div>
      <div :class="['item', active == '/index' ? 'active' : '']" @click="handleClick('/index')">主界面</div>
    </div>
    <div class="center">{{ title }}</div>
    <div class="right">
      <div :class="['item', active == '/event' ? 'active' : '']"  @click="handleClick('/event')">事件记录</div>
      <div :class="['item', 'long-item', active == '/config' ? 'l_active' : '' ]" @click="handleClick('/config')">系统信息及配置</div> 
      <!-- <div class="item" @click="handleClick('/debug')">调试</div> -->
    </div>

    <!-- 时间 -->
    <div class="date">{{ date }}</div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
const weekdays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];

export default {
  name: "Layout",
  data() {
    return {
      title: "发电机成套开关设备在线监测系统",
      date: "",
      timer: null,
      active: '/index', //选中状态
    };
  },
  methods: {
    handleClick(path) {
      if(this.$route.path == path) return
      this.active = path;
      this.$router.push({ path })      
    },
    getDate() {
      this.date = dayjs().format("YYYY-MM-DD") + ' ' + weekdays[dayjs().day()] + ' ' + dayjs().format("HH:mm");
    },
    loop() {
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getDate();
      }, 1000)
    }
  },
  mounted() {
    this.active = this.$route.path; // 获取当前的路由高亮显示
    this.getDate();
    this.loop();
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
    this.timer = null;
  }
  
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
