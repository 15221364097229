<template>
  <div class="verify_popup">
    <el-dialog
      :visible.sync="dialogVisible"
      width="500px"
      center
      top="25vh"
      :close-on-click-modal="false"
      :custom-class="customClass"
      :show-close="false"
    >
      <h3 class="label">请输入账号密码</h3>
      <el-input class="login_input" v-model="username" @focus="onInputFocus('username', username)" placeholder="请输入账号" />
      <el-input class="login_input" v-model="password" @focus="onInputFocus('password', password)" placeholder="请输入密码" type="password" />
      <span slot="footer" class="dialog-footer">
        <el-button class="login_btn" type="primary" @click="login">确 定</el-button>
      </span>
    </el-dialog>

    <KeysInput v-if="showKeyboard" ref="keysInput" @input="updateInputValue"></KeysInput>
  </div>
</template>

<script>
import { isRelogin } from "@/libs/request";
import { setToken, getToken } from "@/libs/auth";
import { Login } from "@/apis/login";
import { getThresholdSeting } from "@/apis/config";

import { mapActions } from "vuex";

export default {
  name: "VerifyPopup",
  data() {
    return {
      showKeyboard: false,
      isRelogin,
      customClass: "custom-dialog",
      dialogVisible: false,
      password: undefined,
      username: undefined,
    };
  },
  methods: {
    ...mapActions("config", ["setThreshold"]),
    onInputFocus(event, value) {
      this.$refs.keysInput.showKeyboard = true;
      // 当前input获取焦点时，显示键盘
      // 传入绑定值（字符串）
      this.$refs.keysInput.key = event;
      //传入当前值
      this.$refs.keysInput.setKeyboardInput(value);
    },
    updateInputValue(value) {
      //根据key 回写input值
      let parameter = value.key.split(".");
      if (parameter.length == 1) {
        this[value.key] = value.value;
      } else {
        let par0 = parameter[0];
        let par1 = parameter[1];
        this[par0][par1] = value.value;
      }
    },
    // 验证token是否有
    getToken() {
      const token = getToken();
      if (!token) {
        this.dialogVisible = true;
      } else {
        this.dialogVisible = false;
        this.fetchThresholdSeting(false);
      }
    },
    login() {
      Login({ username: this.username, password: this.password })
        .then((res) => {
          if (res.code == 200 && res.token) {
            this.$message.success(res.msg);
            this.dialogVisible = false;
            isRelogin.show = false;
            const userInfo = { username: this.username };
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
            setToken(res.token);
            this.fetchThresholdSeting(true);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取阈值
    fetchThresholdSeting(isReload) {
      getThresholdSeting().then((res) => {
        if (res.code == 200) {
          this.setThreshold(res.data);
          if (isReload) {
            window.location.reload(); //登录刷新一下页面
          }
        }
      });
    },
  },
  mounted() {
    this.getToken();
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.password = undefined;
      }
    },
    "isRelogin.show": {
      handler(val) {
        if (val) {
          this.dialogVisible = true;
        }
        this.showKeyboard = val;
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
