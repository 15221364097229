<template>
  <div :class="['module_title', size == 'medium' ? 'medium' : '']">
    <slot></slot>
    <div class="more" v-show="isMore" @click="handleMoreClick">{{moreDesc}}</div>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: {
    size: {
      type: String,
      default: "small",
    },
    isMore: {
      type: Boolean,
      default: false,
    },
    moreDesc: {
      type: String,
      default: "详情",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleMoreClick: debounce(function() {
      this.$emit("handleMoreClick");
    }, 500)
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
