import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('../views/Home/index'),
      },
      {
        path: 'device',
        name: 'Device',
        component: () => import('../views/Device/index'),
      },
      {
        path: 'monitor',
        name: 'Monitor',
        component: () => import('../views/Monitor/index'),
      },
      {
        path: 'event',
        name: 'Event',
        component: () => import('../views/Event/index'),
      },
      {
        path: 'debug',
        name: 'Debug',
        component: () => import('../views/Debug/index'),
      },
      {
        path: 'config',
        name: 'Config',
        component: () => import('../views/Config/index'),
      },
      // {
      //   path: 'todo',
      //   name: 'Todo',
      //   component: () => import('../views/Todo/index'),
      // }
    ]
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
   document.title = "发电机成套开关设备在线监测系统"
  next()
})

export default router
