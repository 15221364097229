// 系统设置
import request from "@/libs/request";

// 对时设置
export function timeSync() {
  return request({
    url: "/system/setting/timeSync",
    method: "post",
  })
}

// 获取预警门限、摄像头地址等信息
export function getThresholdSeting() {
  return request({
    url: "/system/setting/getThresholdSetting",
    method: "get",
  })
}

// 修改密码
export function modifyPassword(data) {
  return request({
    url: "/modifyPassword",
    method: "post",
    data
  })
}

// 设置预警门限、摄像头地址等
export function thresholdSetting(data) {
  return request({
    url: "/system/setting/thresholdSetting",
    method: "post",
    data
  })
}