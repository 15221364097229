const state = {
  threshold: { //阈值配置
    cameraTempThreshold: 0,
    machineLifeNum: 0,
    storageTempThreshold: 0,
    tempSensorThreshold: 0,
    videoLocalAddress: "",
    voltageThreshold: undefined,
    electricThreshold: undefined,
  }
}

const getters = {
}
const mutations = {
  commitThreshold: (state, data) => (state.threshold = data)
}
const actions = {
  setThreshold: ({ commit }, data) => {
    commit('commitThreshold', data)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}