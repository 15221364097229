import Vue from 'vue'
import { Form, FormItem, Input, DatePicker, Button, Select, Option, Table, TableColumn, Dialog, Message, Empty, Loading, Pagination } from 'element-ui';

Vue.use(Form)
Vue.use(Input)
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(FormItem)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(Pagination)
Vue.use(Loading.directive)
Vue.prototype.$message = Message